import React from "react"
import styled from "styled-components"

import { SEO, Layout } from "components"

const FourWrapper = styled.div`
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--whiteChalk);
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="Not Found" />
    <FourWrapper>Sorry, The page not found!</FourWrapper>
  </Layout>
)

export default NotFoundPage
